/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

:root {
  --main-color: #017efa;
  --sidebar-color: #081a51;
  --red-color: #af1a27;
  --white-color: #ffffff;
  --black-color: #000;
  --text-color-black: #000;
  --para-text-color: #292929;
  --inputField-color: #6f6f6f;
  --light-bg-color: #4584ff21;
  --disabled-input-color: #fafafa;
  --disabled-label-color: #ababab;
  --error-color: red;
  --placeholder-color: #979797;
  --heading-color: #1c1f37;
  --grey-color: #ccd2e3;
  --light-grey-color: #f7f7f7;
  --button-light-color: #017efaa8;

  --bg-light-blue: #4584ff21;

  --ff-primary-reg: "poppins-regular";
  --ff-primary-bold: "poppins-bold";
  --ff-primary-semiBold: "poppins-semibold";
  --ff-primary-med: "poppins-medium";
}

/* Poppins */

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("opentype");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/

a {
  font-family: "poppins-regular";
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.t-t-l {
  text-transform: lowercase !important;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

body {
  overflow-x: hidden;
}

body p {
  font-size: 16px;
  font-family: "poppins-regular";
}

.customPara {
  font-size: 16px;
  font-family: "poppins-regular";
}

.textDesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.textDesc4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.textDesc3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.textDesc2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

body h1 {
  font-size: 66px;
  font-family: "poppins-bold";
  line-height: 1.3;
  color: var(--text-color-black);
}

body h2 {
  font-size: 44px;
  font-family: "poppins-bold";
  line-height: 120px;
  color: var(--text-color-black);
}

body h3 {
  font-size: 33px;
  font-family: "poppins-bold";
  color: var(--text-color-black);
}

body h4 {
  font-size: 35px;
  font-family: "poppins-bold";
  color: var(--text-color-black);
}

body h5 {
  font-size: 25px;
  font-family: "poppins-bold";
  color: var(--text-color-black);
}

body h6 {
  font-size: 19px;
  font-family: "poppins-bold";
  color: var(--text-color-black);
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}

body p.large {
  font-size: 22px;
  font-family: "poppins-extraBold";
}

body p.reg {
  font-size: 20px !important;
  font-family: "poppins-bold" !important;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

.mainContainer {
  max-width: 74% !important;
}

.AnimatedButtonMain {
  width: 140px;
  height: 50px;
  color: #fff !important;
  border-radius: 5px;
  font-family: "cairo-meduim" !important;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;
  display: inline-block;
  outline: none;
  font-size: 15px !important;
  background: linear-gradient(0deg,
      var(--main-color) 0%,
      var(--main-color) 100%);
  line-height: 42px;
  padding: 0 !important;
  border: none;
}

.AnimatedButtonMain span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AnimatedButtonMain:before,
.AnimatedButtonMain:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: var(--main-color);
  transition: all 0.5s ease;
}

.AnimatedButtonMain:before {
  height: 0%;
  width: 2px;
}

.AnimatedButtonMain:after {
  width: 0%;
  height: 2px;
}

.AnimatedButtonMain:hover {
  background: transparent;
  box-shadow: none;
}

.AnimatedButtonMain:hover:before {
  height: 100%;
}

.AnimatedButtonMain:hover:after {
  width: 100%;
}

.AnimatedButtonMain span:hover {
  color: var(--main-color);
}

.AnimatedButtonMain span:before,
.AnimatedButtonMain span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: var(--main-color);
  transition: all 0.5s ease;
}

.AnimatedButtonMain span:before {
  width: 2px;
  height: 0%;
}

.AnimatedButtonMain span:after {
  width: 0%;
  height: 2px;
}

.AnimatedButtonMain span:hover:before {
  height: 100%;
}

.AnimatedButtonMain span:hover:after {
  width: 100%;
}

/* ========  Accordion Styling Start ====== */
.accordion-container .accordion-item {
  border: none;
  box-shadow: 0px 5px 14px #00000012 !important;
  border-radius: 37px !important;
  overflow: hidden;
}

.accordion-container button:focus {
  color: #000 !important;
}

.accordion-container .accordion-button {
  font-size: 18px;
  padding: 1rem 2rem !important;
  line-height: 27.6px;
  gap: 0.6rem;
  color: #000 !important;
}

.accordion-container .accordion-body {
  padding: 0 2rem 2rem 2rem !important;
}

.accordion-container {
  margin: 3rem auto;
}

.accordion-container button:not(.collapsed)::after {
  background-image: url("../../assets/images/minus.png");
  color: #000 !important;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
}

.accordion-container button.collapsed::after {
  background-image: url("../../assets/images/plus.png") !important;
  color: #000 !important;
  width: 32px;
  background-position: center;
  background-size: cover;
  height: 32px;
}

.accordion-container button {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

.accordion-container button:not(.collapsed)::after {
  background-image: url("../../assets/images/minus.png");
}

.accordion-container button.collapsed::after {
  background-image: url("../../assets/images/plus.png") !important;
}

/* ========  Accordion Styling End ====== */
/* Swiper Styling */

.swiper {
  --swiper-pagination-bullet-size: 20px;
  --swiper-pagination-color: #dcb062;
  --swiper-pagination-bullet-inactive-color: #dcb062;
  --swiper-pagination-bullet-inactive-opacity: 0.3;
  padding-bottom: 4rem !important;
}

/* Swiper Styling */

@keyframes headerAnimation {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes headerAnimation {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes headerAnimation {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-o-keyframes headerAnimation {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

/* styling for custom carousel */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.activeStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  width: 91px;
  height: 27px;
  background-color: #dcb062;
  border-radius: 25px;
  font-size: 15px;
  margin: 0 auto;
}

.inactiveStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  width: 91px;
  height: 27px;
  background-color: #c6c6c6;
  border-radius: 25px;
  font-size: 15px;
  margin: 0 auto;
}

.mediumStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  width: 91px;
  height: 27px;
  color: #ffffff;
  background-color: #45519e;
  border-radius: 25px;
  font-size: 15px;
  margin: 0 auto;
}

.elipsisOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Media Queries */

@media screen and (max-width: 1600px) {
  .customPara {
    font-size: 15px;
  }
}

@media screen and (max-width: 1440px) {
  body h1 {
    font-size: 60px;
  }

  body h2 {
    font-size: 40px;
    line-height: 81px;
  }

  body h3 {
    font-size: 30px;
  }

  body h4 {
    font-size: 27px;
  }

  .customPara {
    font-size: 15px;
  }
}

@media screen and (max-width: 1366px) {
  body h1 {
    font-size: 55px;
  }

  body h2 {
    font-size: 35px;
  }

  body h3 {
    font-size: 27px;
  }

  body h4 {
    font-size: 24px;
  }

  body h6 {
    font-size: 20px;
  }

  .customPara {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 1199px) {
  body h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 992px) {
  body h1 {
    font-size: 50px;
    line-height: 78px;
  }

  body h2 {
    font-size: 30px;
    line-height: 50px !important;
  }

  body h3 {
    font-size: 27px;
  }

  body h4 {
    font-size: 22px;
  }

  body h6 {
    font-size: 19px;
  }

  body p {
    font-size: 16px;
  }

  .customPara {
    font-size: 13px;
  }

  body p.reg,
  button {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  body h1 {
    font-size: 46px;
    line-height: 69px;
  }

  body h2 {
    font-size: 28px;
    line-height: 40px;
  }

  body h3 {
    font-size: 25px;
  }

  body h4 {
    font-size: 21px;
  }
}